///* SCSS HSL */
$linen: hsl(34deg 49% 91%);
$caramel: hsl(28deg 47% 54%);
$caput-mortuum: hsl(13deg 46% 25%);
$raw-umber: hsl(23deg 43% 38%);
$desert-sand: hsl(23deg 45% 74%);
// Path to Bulma's sass folder

//@import "bulma/css/bulma.min.css";

//@use "bulma/sass" with (
//  $family-primary: '"Montserrat", sans-serif',
//  $scheme-main: $caput-mortuum,
//  $text: $caput-mortuum,
//  $primary: $caput-mortuum,
//);
//
//@use "bulma/sass/utilities/css-variables";



// Import the Google Font
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
// Set your brand colors
@import "pages/events";

:root {
  --bulma-scheme-h: 23;
  --bulma-scheme-s: 43%;
  --bulma-scheme-main: hsl(23deg 43% 98%);
  //--bulma-scheme-main-l: 98%;
  --bulma-scheme-main-bis-l: 90%;
  --bulma-scheme-main-ter-l: 94%;
  --bulma-light-h: 23;
  --bulma-light-s: 43%;
  --bulma-light-l: 96%;

  //--bulma-primary-h: ;
  //--bulma-primary-s: $caput-mortuum.s;
  //--bulma-text-h: 13;
  //--bulma-text-s: 46%;

  //--bulma-scheme-main-bis-l: 74%;

  //--bulma-light-l: 90%;
  //--bulma-light-invert-l: 20%;
  //--bulma-dark-l: 20%;
  //--bulma-dark-invert-l: 90%;
  //--bulma-soft-l: 90%;
  //--bulma-bold-l: 20%;
  //--bulma-soft-invert-l: 20%;
  //--bulma-bold-invert-l: 90%;

  //--bulma-link-h: 28deg;
  //--bulma-link-s: 47%;
  //--bulma-link-l: 54%;

  --bulma-link-h: 128deg;
  --bulma-link-s: 38%;
  --bulma-link-l: 30%;

  --bulma-link-on-scheme-l: 30%;
}

//rgb(48 106 56);
// #306b38


//.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
//  width: 1.6rem;
//  line-height: 1.6rem;
//}

body {
  .react-datepicker {
    border: 1px solid var(--bulma-border);
    display: flex;
  }

  .react-datepicker__time-list-item--disabled {
    display: none;
  }

  .react-datepicker-popper {
    z-index: 10;
  }

  .react-datepicker__day--selected {
    background-color: var(--bulma-primary);
  }

  .react-datepicker__day--selected:not([aria-disabled="true"]):hover, .react-datepicker__day:not([aria-disabled="true"]):hover {
    background-color: var(--bulma-primary-light);
  }

  .react-datepicker__header {
    background-color: $caput-mortuum;

    .react-datepicker__day-name, h2.react-datepicker__current-month {
      color: white !important;
    }

  }

  .react-datepicker-popper .react-datepicker__triangle {
    stroke: var(--bulma-border);
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    color: var(--bulma-primary);
    fill: var(--bulma-primary);
  }

  //a {
  //  color: var(--bulma-primary-75);
  //  font-weight: 800;
  //}

  .fc-view a {
    color: var(--bulma-primary);
  }

  .fc-col-header {
    background-color: var(--bulma-primary);

    a {
      color: var(--bulma-primary-invert);
    }
  }

  --fc-small-font-size: .85em;
  --fc-page-bg-color: #fff;
  --fc-neutral-bg-color: hsla(0deg 0% 82% 30%);
  --fc-neutral-text-color: grey;
  --fc-border-color: var(--bulma-border);
  --fc-button-text-color: #fff;
  --fc-button-bg-color: var(--bulma-primary);
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: var(--bulma-primary-15);
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;
  --fc-event-bg-color: #3788d8;
  --fc-event-border-color: #3788d8;
  --fc-event-text-color: #fff;
  --fc-event-selected-overlay-color: rgba(0 0 0 25%);
  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;
  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;
  --fc-non-business-color: hsla(0deg 0% 84% 30%);
  --fc-bg-event-color: #8fdf82;
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188 232 241 30%);
  --fc-today-bg-color: rgba(255 220 40 15%);
  --fc-now-indicator-color: red;
}

